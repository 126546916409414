import { useState, Fragment } from 'react';
import useGlobal from "../../store/index";
import i18next from 'i18next';
import resultsapi from '../../apis/Results';
import { soundex } from 'soundex-code';
import Autocomplete from '../UI/Autocomplete/Autocomplete';
import Badge from '../UI/Badge/Badge';
import { de } from 'date-fns/locale';
import { DateRangePicker, START_DATE, END_DATE } from '@axel-dev/react-nice-dates';
import Clearer from '../UI/Clearer/Clearer';

const Finder = () => {
    const [globalState, globalActions] = useGlobal();
    const [destinationSelected, setDestinationSelected] = useState('');
    const [tagsSelected, setTagsSelected] = useState([]);

    let classesContainer = "relative z-1 max-w-5xl mx-auto md:-mt-20 p-4 md:px-4 md:pt-4 md:pb-4 lg:px-6 lg:pt-6 lg:pb-6 bg-gray-100 md:shadow-lg xl:-mt-48";
    let classesWrapper = "flex flex-col md:flex-row md:items-start justify-between gap-y-3 md:gap-4 lg:gap-6 mb-4";
    let classesInput = "flex-1";
    if (globalState.appconfig.reduced) {
        classesContainer = "px-4 py-8 md:p-8 bg-gray-100 rounded md:shadow-lg z-10 md:absolute md:top-16 md:right-8 w-full md:w-2/3 lg:w-[45%]";
        classesWrapper = "mb-3 md:mb-6";
        classesInput = "mt-1 relative rounded-md shadow-sm";
    }

    const filterByString = (options, value) => {
        let filtered = [];
        if (value.length > 2) {
            for (let i in options) {
                let countryName = options[i].name;
                // console.log('s1', soundex(value));
                // console.log('s2', soundex(countryName.substr(0, value.length)));
                if (soundex(value) === soundex(countryName.substr(0, value.length))) {
                //if (value.toLowerCase() === countryName.substr(0, value.length).toLowerCase()) {
                    filtered.push(options[i]);
                }
            }
        }
        else {
            filtered = [...options];
        }
        return filtered;
    }

    const handleDestinationsChange = (option) => {
        globalActions.filter.setFilterstate({
            country: option !== null ? option.uid : ''
        }, resultsapi);
        setDestinationSelected(option !== null ? option.name : '');
    }

    const destinationFilter = (options, value) => {
        if (value.trim() === '') {
            value = '';
        }
        if (value === '') {
            globalActions.filter.setFilterstate({
                country: ''
            }, resultsapi);
        }
        setDestinationSelected(value);
        return filterByString(options, value);
    }

    const handleTagsChange = (option) => {
        let selected = [...tagsSelected];
        for (let i in globalState.filterdata.tags) {
            if (globalState.filterdata.tags[i].uid === parseInt(option.target.value, 10)) {
                selected.push(globalState.filterdata.tags[i]);
            }
        }
        let tags = [];
        for (let i in selected) {
            tags.push(selected[i].uid)
        }
        globalActions.filter.setFilterstate({
            tags
        }, resultsapi);
        setTagsSelected(selected);
    }

    const handleTagDelete = (id) => {
        let selected = [];
        for (let i in tagsSelected) {
            if (tagsSelected[i].uid !== id) {
                selected.push(tagsSelected[i]);
            }
        }
        let tags = [];
        for (let i in selected) {
            tags.push(selected[i].uid)
        }
        globalActions.filter.setFilterstate({
            tags
        }, resultsapi);
        setTagsSelected(selected);
    }

    const tagsOptions = [];
    for (let i in globalState.filterdata.tags) {
        let checked = tagsSelected.some(function (tag) {
            if (tag.uid === globalState.filterdata.tags[i].uid) {
                return true;
            }
            return false;
        })
        if (!checked) {
            tagsOptions.push(globalState.filterdata.tags[i]);
        }        
    }

    // date range //
    const handleStartDateChange = (date) => {
        globalActions.filter.setFilterstate({
            startDate: date
        }, resultsapi);
    }

    const clearStartDate = () => {
        globalActions.filter.setFilterstate({
            startDate: null
        }, resultsapi);
    }

    const handleEndDateChange = (date) => {
        globalActions.filter.setFilterstate({
            endDate: date
        }, resultsapi);
    }

    const clearEndDate = () => {
        globalActions.filter.setFilterstate({
            endDate: null
        }, resultsapi);
    }

    const Results = () => {
        if (!globalState.results.numTotal) {
            return <span className="font-sans text-secondary text-sm">{i18next.t('main:finder.nohits')}</span>
        }
        else {
            if (globalState.results.numTotal === 1) {
                return <span className="font-sans text-secondary text-sm">{i18next.t('main:finder.onehit')}</span>
            }
            else {
                return <span className="font-sans text-secondary text-sm">{globalState.results.numTotal} {i18next.t('main:finder.numhits')}</span>
            }
        }
    }

    const Button = () => {
        sessionStorage.removeItem(globalState.appconfig.brand + 'Finder');
        let toursLink = globalState.appconfig.data.redirecturl;
        let buttonCaption = i18next.t('main:finder.submitall');

        if (globalState.results.numTotal) {
            buttonCaption = i18next.t('main:finder.submit');
            let storageData = {
                filterstate: globalState.filterstate
            }
            sessionStorage.setItem(globalState.appconfig.brand + 'Finder', JSON.stringify(storageData));            
        }
        return (
            <a href={toursLink} className="btn btn-secondary">{buttonCaption}</a>
        );

    }

    return (
        <div className={classesContainer}>
            { globalState.appconfig.reduced ?
                <h3 class="home">{i18next.t('main:finder.title')}</h3>
            :
                <h2 className="text-secondary mt-0 mb-2 text-xl md:text-3xl">{i18next.t('main:finder.title')}</h2>
            }
            <div className={classesWrapper}>
                <div className={classesInput}>
                    <div className="order-1">
                        { globalState.appconfig.reduced ? null :
                            <label htmlFor="" className="hidden md:block text-sm md:text-base font-medium font-sans text-gray-700 mb-2">
                                {i18next.t('main:finder.destination.title')}
                            </label>
                        }
                        <Autocomplete
                            id="destination"
                            name="destination"
                            value={destinationSelected}
                            options={globalState.filterdata.destinations}
                            onChange={handleDestinationsChange}
                            onFilter={destinationFilter}
                            placeholder={globalState.appconfig.reduced ? i18next.t('main:finder.destination.placeholderreduced') : i18next.t('main:finder.destination.placeholder')}
                        />
                    </div>
                </div>
                { globalState.appconfig.reduced ? null :
                    <div className="flex-1">
                        <div className="order-2">
                            <label htmlFor="" className="hidden md:block text-sm md:text-base font-medium font-sans text-gray-700 mb-2">
                                {i18next.t('main:finder.tags.title')}
                            </label>
                            <select
                                id="tags"
                                name="tags"
                                className="text-gray-500 focus:ring-green-500 focus:border-green-500 block pr-10 sm:text-sm border-gray-300 rounded-md w-full"
                                onChange={handleTagsChange}
                            >
                                <option value='' selected hidden className='text-gray-300'>{i18next.t('main:finder.tags.placeholder')}</option>
                                {tagsOptions.map(tag => (
                                    <option key={tag.uid} value={tag.uid}>{tag.name} ({tag.count})</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            {tagsSelected.map(tag => (
                                <Badge
                                    key={tag.uid}
                                    uid={tag.uid}
                                    name={tag.name}
                                    onClick={handleTagDelete}
                                />
                            ))}
                        </div>
                    </div>
                }
                { globalState.appconfig.reduced ? null :
                    <div className="flex-1">
                        <DateRangePicker
                            startDate={globalState.filterstate.startDate}
                            endDate={globalState.filterstate.endDate}
                            onStartDateChange={handleStartDateChange}
                            onEndDateChange={handleEndDateChange}
                            format='dd.MM.yy'
                            minimumDate={new Date()}
                            minimumLength={1}
                            locale={de}
                        >
                            {({ startDateInputProps, endDateInputProps, focus }) => (
                                <div className='date-range'>
                                    <label htmlFor="startDate" className="hidden md:block text-sm md:text-base font-medium font-sans text-gray-700 mb-2">
                                        {i18next.t('main:filter.input.date.title')}
                                    </label>
                                    <div className="flex space-x-1">
                                        <div className="relative rounded-md shadow-sm flex-1">
                                            <input
                                                className={'focus:ring-green-500 focus:border-green-500 block pr-10 sm:text-sm border-gray-300 rounded-md w-full ' + (focus === START_DATE ? '' : '')}
                                                {...startDateInputProps}
                                                id="startDate"
                                                placeholder={i18next.t('main:filter.input.date.from.title')}
                                                autoComplete="off"
                                            />
                                            <Clearer onClick={clearStartDate} displayCond={globalState.filterstate.startDate} />
                                        </div>
                                        <span className='date-range_arrow' />
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 -mx-1 mt-2 align-middle pt-1 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                                            </svg>
                                        <div className="relative rounded-md shadow-sm flex-1">
                                            <input
                                                className={'focus:ring-green-500 focus:border-green-500 block pr-10 sm:text-sm border-gray-300 rounded-md w-full' + (focus === END_DATE ? '' : '')}
                                                {...endDateInputProps}
                                                id="endDate"
                                                placeholder={i18next.t('main:filter.input.date.to.title')}
                                                autoComplete="off"
                                            />
                                            <Clearer onClick={clearEndDate} displayCond={globalState.filterstate.endDate} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </DateRangePicker>
                    </div>
                }
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center gap-y-3 md:gap-4 lg:gap-6">
                <div className="flex-1 inline-flex items-end">
                    {globalState.results.searched ? 
                        <Fragment>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline text-primary mr-1 animate-pulse" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                            </svg>                            
                            <Results />
                        </Fragment> : null }
                </div>
                <div className="flex-none">
                    <Button />
                </div>
            </div>            
        </div>
        
    );
}

export default Finder;