const i18config = {
    //debug: process.env.NODE_ENV === 'development',
    interpolation: {
    // React already does escaping
    escapeValue: false,
    },
    lng: 'de',
    fallbackLng: 'de',
    load: 'languageOnly',
    ns: ['main'],
    defaultNS: 'main',
    backend: {
        loadPath: process.env.NODE_ENV === 'development' ? '/typo3conf/ext/globetrottertoursreact/Resources/Public/Locales/NT/{{lng}}/{{ns}}.json' : '/typo3conf/ext/globetrottertoursreact/Resources/Public/Locales/NT/{{lng}}/{{ns}}.json',
        crossDomain: true
    }
};

export default i18config;
