import axios from 'axios';
import CONFIG from '../config/main';

const Api = axios.create({
    baseURL: CONFIG.axios.baseURL + '/nt',
    auth: {
        username: CONFIG.axios.username,
        password: CONFIG.axios.password,
    }
});

export default Api;
